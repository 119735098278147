/* global _ */
'use strict'
const ModelSelectHelper = require('app/formly/helpers/model-select-helper.class.js')

require('./modelSelect.scss')
/** @ngInject */
function modelSelect ($scope, $timeout, $mdSelect) {
  let input
  $scope.searchTerm = ''
  $scope.findMethod = $scope.to.findMethod
  $scope.preloadOptions = $scope.to.preloadOptions
  $scope.allowEmpty = $scope.to.allowEmpty || false
  let openCounter = 0
  // $scope.baseFilterObject = $scope.to.baseFilterObject
  $scope.templateOption = $scope.to.templateOption
  $scope.showDeleted = $scope.to.showDeleted || false
  let modelSelectHelper

  const setBaseFilterObject = function setBaseFilterObject () {
    if (typeof $scope.to.baseFilterObject === 'function') {
      $scope.baseFilterObject = _.clone(
        $scope.to.baseFilterObject() || { where: {} }
      )
    } else {
      $scope.baseFilterObject = _.clone(
        $scope.to.baseFilterObject || { where: {} }
      )
    }
    if (modelSelectHelper) {
      modelSelectHelper.setBaseFilterObject($scope.baseFilterObject)
      modelSelectHelper.reset()
      initSelect()
      openCounter = 0
    }
  }
  $scope.mapObject = $scope.to.mapObject || {
    id: 'id',
    name: 'name',
    displayName: 'displayName'
  }

  // Helper function to get displayName value, supporting both string property names and functions
  const getDisplayName = function (option) {
    if (!option) return ''

    const displayNameProp = $scope.mapObject.displayName
    if (typeof displayNameProp === 'function') {
      return displayNameProp(option)
    } else if (typeof displayNameProp === 'string') {
      return option[displayNameProp]
    }
    return ''
  }

  // Process options to add computed display names
  const processOptionsWithDisplayNames = function (options) {
    if (!options || !Array.isArray(options)) return options

    return options.map(option => {
      // Only compute if we have a function for displayName
      if (typeof $scope.mapObject.displayName === 'function') {
        // Add a computed property to avoid calling the function from the template
        option._computedDisplayName = getDisplayName(option)
      }
      return option
    })
  }

  setBaseFilterObject()

  $scope.extraOption = $scope.to.extraOption || null
  const focusInput = function focusInput () {
    $timeout(
      () => {
        const inputElement = document.querySelector(
          '.md-select-menu-container.md-active .selectboxWithSearch input'
        )
        if (inputElement) {
          inputElement.focus()
        }
      },
      200,
      false
    )
  }

  modelSelectHelper = new ModelSelectHelper(
    $scope.findMethod,
    $scope.baseFilterObject,
    $scope.mapObject,
    $scope.model,
    $scope.options.key,
    $scope.showDeleted
  )

  $scope.search = $event => {
    input = $event.target
    if (typeof input.value === 'string' && input.value !== $scope.searchTerm) {
      $scope.searchTerm = input.value.trim()
      $scope.debouncedFn(input.value.trim())
    }
  }

  $scope.onSelect = function onSelect () {
    const value = _.get($scope.model, $scope.options.key)
    if (!$scope.to.multiple) {
      $mdSelect.hide()
      if (input) {
        input.value = ''
      }
    }
    if (_.isArray($scope.options.formControl)) {
      $scope.options.formControl = $scope.options.formControl[0]
    }
    let selectedOption = null
    if (Array.isArray(value)) {
      selectedOption = $scope.optionsToDisplay.filter(option =>
        value.includes(option[$scope.mapObject.id])
      )
    } else {
      selectedOption = $scope.optionsToDisplay.find(
        option => option[$scope.mapObject.id] === value
      )
    }
    $scope.selectedOption = selectedOption
    if (typeof $scope.to.onChange === 'function') {
      $scope.to.onChange(value, $scope.options, $scope.model, selectedOption)
    }
    $scope.$applyAsync()
  }

  $scope.clearSearchTerm = function clearSearchTerm () {
    const value = _.get($scope.model, $scope.options.key)
    if ($scope.to.multiple === true && Array.isArray(value)) {
      const selectedOptions = $scope.optionsToDisplay.filter(option =>
        value.includes(option[$scope.mapObject.id])
      )
      $scope.selectedOption = selectedOptions
    } else if (value) {
      const selectedOption = $scope.optionsToDisplay.find(
        option => option[$scope.mapObject.id] === value
      )
      $scope.selectedOption = selectedOption
    } else {
      $scope.selectedOption = null
    }
    if (input) {
      input.value = ''
    }
    $scope.searchTerm = ''
    $scope.makeSearch('')
    $scope.noMoreResults = false
  }

  $scope.mdSelectOnOpen = function mdSelectOnOpen () {
    $scope.$broadcast('$md-resize')
    return $timeout(function () {
      $scope.$broadcast('$md-resize')
    }, 100)
  }

  $scope.makeSearch = async function makeSearch (query, imFeelingLucky = false) {
    $scope.loadingMore = $scope.noMoreResults = false
    $scope.initLoading = true
    $scope.$applyAsync()
    $scope.optionsToDisplay = []
    const res = await modelSelectHelper.makeSearch(query, imFeelingLucky)

    // Process options to add computed display names
    const processedRes = processOptionsWithDisplayNames(res)

    if (!_.isNil($scope.selectedOption)) {
      if ($scope.to.multiple === true && Array.isArray($scope.selectedOption)) {
        const nonExistsOptions = $scope.selectedOption.filter(
          option =>
            !processedRes.find(
              opt => opt[$scope.mapObject.id] === option[$scope.mapObject.id]
            )
        )
        if (nonExistsOptions.length > 0) {
          $scope.optionsToDisplay = [
            ...processOptionsWithDisplayNames(nonExistsOptions),
            ...processedRes
          ]
        } else {
          $scope.optionsToDisplay = processedRes
        }
      } else if (
        !processedRes.find(
          opt =>
            opt[$scope.mapObject.id] ===
            $scope.selectedOption[$scope.mapObject.id]
        )
      ) {
        $scope.optionsToDisplay = [
          ...processOptionsWithDisplayNames([$scope.selectedOption]),
          ...processedRes
        ]
      } else {
        $scope.optionsToDisplay = processedRes
      }
    } else {
      $scope.optionsToDisplay = processedRes
    }
    $scope.initLoading = false
    $scope.$applyAsync()
  }

  const loadMoreDebounce = _.debounce(
    async () => {
      $scope.loadingMore = true
      const moreOptions = await modelSelectHelper.loadMore()

      // Process new options to add computed display names
      const processedMoreOptions = processOptionsWithDisplayNames(moreOptions)

      if (processedMoreOptions.length === 0) {
        $scope.noMoreResults = true
      } else {
        $scope.noMoreResults = false
      }
      const uniqueIds = new Set(
        $scope.optionsToDisplay.map(opt => opt[$scope.mapObject.id])
      )
      $scope.optionsToDisplay.push(
        ...processedMoreOptions.filter(
          opt => !uniqueIds.has(opt[$scope.mapObject.id])
        )
      )
      $scope.loadingMore = false
      $scope.$applyAsync()
    },
    500,
    { leading: true }
  )

  $scope.loadMore = async function loadMore () {
    if ($scope.loadingMore || $scope.noMoreResults) return
    loadMoreDebounce()
  }

  $scope.onOpen = async function onOpen () {
    openCounter++
    if (openCounter === 1) {
      // Call fillOptions for the first onOpen event
      fillOptions()
    }
  }

  const fillOptions = async function fillOptions () {
    if ($scope.initLoading) {
      focusInput()
      return
    }
    if (typeof $scope.to.baseFilterObject === 'function') {
      modelSelectHelper = new ModelSelectHelper(
        $scope.findMethod,
        $scope.to.baseFilterObject(),
        $scope.mapObject,
        $scope.model,
        $scope.options.key,
        $scope.showDeleted
      )
    }
    // if (!modelSelectHelper.alreadyInit()) {
    await modelSelectHelper.getFirstOptions()
    // }
    const options = modelSelectHelper.getOptionsToDisplay()
    $scope.optionsToDisplay = processOptionsWithDisplayNames(options)

    if (!_.isNil($scope.selectedOption)) {
      if ($scope.to.multiple === true && Array.isArray($scope.selectedOption)) {
        const nonExistsOptions = $scope.selectedOption.filter(
          option =>
            !$scope.optionsToDisplay.find(
              opt => opt[$scope.mapObject.id] === option[$scope.mapObject.id]
            )
        )
        if (nonExistsOptions.length > 0) {
          $scope.optionsToDisplay = [
            ...processOptionsWithDisplayNames(nonExistsOptions),
            ...$scope.optionsToDisplay
          ]
        }
      } else {
        const hasSelectInOptions = $scope.optionsToDisplay.find(
          opt =>
            opt[$scope.mapObject.id] ===
            $scope.selectedOption[$scope.mapObject.id]
        )
        if (!hasSelectInOptions) {
          $scope.optionsToDisplay = [
            ...processOptionsWithDisplayNames([$scope.selectedOption]),
            ...$scope.optionsToDisplay
          ]
        }
      }
    }
    focusInput()
  }

  const setSelectedOption = async function setSelectedOption (option) {
    const value = option[$scope.mapObject.id]
    _.set($scope.model, $scope.options.key, value)

    // Process the option to add computed display name
    const processedOption = processOptionsWithDisplayNames([option])[0]

    if (
      !$scope.optionsToDisplay.find(
        option => option[$scope.mapObject.id] === value
      )
    ) {
      $scope.optionsToDisplay.unshift(processedOption)
    }
    if (typeof $scope.to.onChange === 'function') {
      $scope.to.onChange(value, $scope.options, $scope.model, processedOption)
    }
    $scope.$applyAsync()
  }

  $scope.extraOptionClick = async function extraOptionClick (ev) {
    ev.preventDefault()
    ev.stopPropagation()
    $mdSelect.hide()
    if ($scope.extraOption && typeof $scope.extraOption.action === 'function') {
      const option = await $scope.extraOption.action($scope.model)
      if (option) {
        setSelectedOption(option)
      }
    }
  }

  const initSelect = async function initSelect () {
    $scope.initLoading = true
    const initialValue = _.get($scope.model, $scope.options.key)
    if (
      (Array.isArray(initialValue) && initialValue.length > 0) ||
      (!Array.isArray(initialValue) && initialValue !== null)
    ) {
      await modelSelectHelper.initOptions($scope.preloadOptions)
      const options = modelSelectHelper.getOptionsToDisplay()
      $scope.optionsToDisplay = processOptionsWithDisplayNames(options)

      let selectedOption = null
      if ($scope.to.multiple === true && Array.isArray(initialValue)) {
        selectedOption = $scope.optionsToDisplay.filter(option =>
          initialValue.includes(option[$scope.mapObject.id])
        )
      } else {
        selectedOption = $scope.optionsToDisplay.find(
          option => option[$scope.mapObject.id] === initialValue
        )
      }
      $scope.selectedOption = selectedOption
      if (typeof $scope.to.onInit === 'function') {
        $scope.to.onInit(selectedOption, $scope.options, $scope.model)
      }
    } else {
      $scope.selectedOption = null
    }
    $scope.initLoading = false
    $scope.$applyAsync()
  }
  if (typeof $scope.to.baseFilterObject === 'function') {
    $scope.$watch(
      'to.baseFilterObject()',
      (newValue, oldValue) => {
        if (_.isEqual(newValue, oldValue)) return
        setBaseFilterObject()
      },
      true
    )
  } else {
    $scope.$watch(
      'to.baseFilterObject',
      (newValue, oldValue) => {
        if (_.isEqual(newValue, oldValue)) return
        setBaseFilterObject()
      },
      true
    )
  }

  $scope.$watch(
    `model['${$scope.options.key}']`,
    (newValue, oldValue) => {
      if (_.isEqual(newValue, oldValue)) return
      modelSelectHelper.model = $scope.model
      if ($scope.optionsToDisplay?.length > 0) {
        const value = _.get($scope.model, $scope.options.key)
        const option = $scope.optionsToDisplay.find(
          option =>
            option[$scope.mapObject.id] === value ||
            (Array.isArray(value) &&
              value.includes(option[$scope.mapObject.id]))
        )
        if (!option) {
          initSelect()
        }
      }
    },
    true
  )
  $scope.$watch(
    'to.preloadOptions',
    (newValue, oldValue) => {
      if (_.isEqual(newValue, oldValue)) return
      $scope.preloadOptions = newValue
      initSelect()
    },
    true
  )

  initSelect()
  $scope.debouncedFn = _.debounce($scope.makeSearch, 200, { leading: false })
}

module.exports = modelSelect
